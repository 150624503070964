<template>
  <div class="card-panel">
    <div class="card-content" v-if="cardType.length">
      <div class="header">
        <div class="header-left">
          <Carousel v-model="carouselIndex" dots="none" @on-change="handleCarouselChange">
            <CarouselItem v-for="item in cardType" :key="item.name">
              <div
                class="card-pic"
                :style="{ backgroundImage: `url(${item.tp})` }"
              ></div>
            </CarouselItem>
          </Carousel>
          <div class="header-content">
            <div class="card-info">
              <div class="card-name">{{ selectedCard.name }}</div>
              <div class="card-tip">卡系简介：{{ selectedCard.jj }}</div>
            </div>
            <div class="card-overview">
              <div class="card-circle">
                <p>{{ cardStatistics.rs_sq || 0 }}</p>
                <p>申请人数</p>
              </div>
              <div class="card-circle">
                <p>{{ cardStatistics.rs_tg || 0 }}</p>
                <p>通过人数</p>
              </div>
              <div class="card-circle">
                <p>{{ cardStatistics.rs_shz || 0 }}</p>
                <p>审核中人数</p>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <p class="header-title">卡系申请情况统计</p>
          <div class="flex-around">
            <div class="chart-circle orange">
              <p>{{ cardStatistics.zsl_sqz || 0 }}</p>
              <p>申请中总数量</p>
            </div>
            <div class="chart-circle blue">
              <p>{{ cardStatistics.zsl_sq || 0 }}</p>
              <p>申请总数量</p>
            </div>
            <div class="chart-circle pink">
              <p>{{ cardStatistics.zsl_bh || 0 }}</p>
              <p>驳回总数量</p>
            </div>
          </div>
          <div class="flex-around">
            <div class="chart-text orange">
              开卡申请：{{ cardStatistics.sq_kk || 0 }}
            </div>
            <div class="chart-text blue">
              暂停服务申请：{{ cardStatistics.sq_zt || 0 }}
            </div>
            <div class="chart-text green">
              重启服务申请：{{ cardStatistics.sq_cq || 0 }}
            </div>
            <div class="chart-text red">
              退卡申请：{{ cardStatistics.sq_tk || 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content bg-white pt-20px">
      <div class="header">
        <div class="header-action">
          <span>注册手机号</span>
          <Input
            v-model="cardQuery.mobile"
            placeholder="请输入注册手机号"
            style="width: 130px"
          />
          <span>姓名</span>
          <Input
            v-model="cardQuery.name"
            placeholder="请输入姓名"
            style="width: 120px"
          />
          <span>申请内容</span>
          <Select v-model="cardQuery.sqnr" style="width: 120px">
            <Option value="1">开卡</Option>
            <Option value="2">暂停服务</Option>
            <Option value="3">重启服务</Option>
            <Option value="5">续卡</Option>
            <Option value="4">退卡</Option>
          </Select>
          <Button @click="resetQuery">重置</Button>
        </div>
        <div class="header-btn">
          <Button type="info">导出报表</Button>
        </div>
      </div>
      <div class="header">
        <div class="header-action">
          <span>申请审核时间</span>
          <DatePicker
            type="daterange"
            placeholder="选择日期"
            style="width: 200px"
            @on-change="onChangeTime"
          ></DatePicker>
          <span>申请员工</span>
          <Select v-model="cardQuery.staff_id" style="width: 120px">
            <Option
              v-for="item in staffs"
              :key="item.id"
              :value="item.id"
              >{{ item.true_name }}</Option
            >
          </Select>
          <span>审批状态</span>
          <Select v-model="cardQuery.spzt" style="width: 120px">
            <Option value="1">等待审批</Option>
            <Option value="2">通过</Option>
            <Option value="3">驳回</Option>
          </Select>
          <Button type="info" @click="handleQuery">查询</Button>
        </div>
      </div>
      <div class="table-panel">
        <Table :columns="dataColumns" :data="dataList"></Table>
        <div>
          <span class="records">共 {{ dataTotal }} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="dataTotal"
              :current="dataCurrentPage"
              :page-size="cardQuery.size"
              @on-change="changeDataPage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showPreviewModal"
      title="图片预览"
      class-name="vertical-center-modal"
      width="800">
      <div class="preview-wrapper" v-if="previewImage">
        <div class="img-wrapper">
          <img :src="previewImage" />
        </div> 
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			carouselIndex: 0,
			selectedCard: {},
			cardQuery: {
				kxlx_id: '',
				page: 1,
				size: 30,
				mobile: '',
				name: '',
				start: '',
				end: '',
				staff_id: '',
				sqnr: '',
				spzt: '',
			},
			dataColumns: [
				{
					title: '卡系名称',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#ff8400',
								},
							},
							params.row.kxmc,
						);
					},
				},
				{
					title: '会员名称',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.member_name,
						);
					},
				},
				{ title: '手机号', key: 'mobile', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{
					title: '卡系状态',
					key: 'kxzt',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.kxzt === 2 ? '#4cae32' : params.row.kxzt === 3 ? '#f07844' : 'red',
								},
							},
							params.row.kxzt_name,
						);
					},
				},
				{
					title: '申请内容',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.sqnr_name === '开卡'
											? '#4cae32'
											: params.row.sgnr_name === '退卡'
												? 'red'
												: '#f07844',
								},
							},
							params.row.sqnr_name,
						);
					},
				},
				{ title: '申请时间', key: 'sqsj', align: 'center' },
				{
					title: '申请人',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.sqr_name,
						);
					},
				},
				{
					title: '签字单',
					align: 'center',
					render: (h, params) => {
						return h('img', {
							style: { height: '60px', width: '40px', margin: '5px 0' },
							attrs: { src: params.row.qzd },
							on: {
								click: () => this.handlePreviewImg(params.row.qzd),
							},
						});
					},
				},
				{
					title: '审批状态',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.spzt === 2 ? '#4cae32' : params.row.spzt === 1 ? '#4495f0' : 'red',
								},
							},
							params.row.spzt_name,
						);
					},
				},
				{ title: '审批时间', key: 'spsj', align: 'center' },
				{
					title: '审批人',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.spr_name,
						);
					},
				},
			],
			cardType: [],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			cardStatistics: {},
			staffs: [],
			previewImage: '',
			showPreviewModal: false,
			havePermission: false,
		};
	},
	created() {
		cardService.getCardType().then((data) => {
			this.cardType = data;
			this.selectedCard = data[0];
			this.getCardApplication();
			this.getStaffs();
		});
	},
	methods: {
		getStaffs() {
			cardService
				.getStaffs({
					kxlx_id: this.selectedCard.id,
					user_type: 2,
				})
				.then((data) => {
					this.staffs = data;
				});
			cardService
				.getStaffs({
					kxlx_id: this.selectedCard.id,
					user_type: 3,
				})
				.then((data) => {
					if (
						data.findIndex(
							(item) => item.id === JSON.parse(localStorage.getItem('userInfo')).user_info.id,
						) > -1
					) {
						this.dataColumns.push({
							title: '操作',
							align: 'center',
							render: (h, params) => {
								if (params.row.spzt === 1) {
									const actions = [
										h(
											'Button',
											{
												attrs: {
													type: 'info',
													size: 'small',
												},
												style: {
													marginRight: '5px',
												},
												on: {
													click: () => {
														this.approveCardService(2, params.row.id);
													},
												},
											},
											'通过',
										),
										h(
											'Button',
											{
												attrs: {
													type: 'error',
													size: 'small',
												},
												on: {
													click: () => {
														this.approveCardService(3, params.row.id);
													},
												},
											},
											'驳回',
										),
									];
									return actions;
								}
								return '';
							},
						});
					}
				});
		},
		onChangeTime(val) {
			this.cardQuery.start = val[0];
			this.cardQuery.end = val[1];
		},
		getCardApplication() {
			this.cardQuery.kxlx_id = this.selectedCard.id;
			cardService.getCardApplication(this.cardQuery).then((data) => {
				this.dataTotal = data.row_size;
				this.dataCurrentPage = data.cur_page;
				this.dataList = data.list;
				this.cardStatistics = data.tj;
			});
		},
		handleQuery() {
			this.cardQuery.page = 1;
			this.getCardApplication();
		},
		changeDataPage(page) {
			this.cardQuery.page = page;
			this.getCardApplication();
		},
		approveCardService(status, id) {
			const params = {
				jl_id: id,
				spzt: status,
			};
			cardService.approveCardService(params).then(() => {
				this.$Message.success('操作成功');
				this.getCardApplication();
			});
		},
		handleCarouselChange(old, index) {
			this.selectedCard = this.cardType[index];
			this.cardQuery.page = 1;
			this.getCardApplication();
			this.getStaffs();
		},
		handlePreviewImg(image) {
			this.showPreviewModal = true;
			this.previewImage = image;
		},
		resetQuery() {
			this.cardQuery = {
				page: 1,
				size: 30,
				mobile: '',
				name: '',
				start: '',
				end: '',
				staff_id: '',
				sqnr: '',
				spzt: '',
			};
		},
	},
};
</script>

<style lang="less" scoped>
.card-content {
  margin-bottom: 20px;
}
.header {
  background-color: #fff;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
  &-left {
    flex: 1;
    padding: 10px 20px;
    border-right: #c8c8c8 solid 1px;
    display: flex;
    .ivu-carousel {
      width: 402px;
    }
  }
  &-right {
    flex: 1;
    padding: 10px 20px;
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
  }
  &-content {
    flex: 1;
    margin-left: 15px;
  }
  &-action {
    span {
      margin: 0 15px;
    }
  }
  &-btn {
    margin-right: 20px;
  }
}
.bg-white {
  background-color: #fff;
}
.pt-20px {
  padding-top: 20px;
}
.table-panel {
  padding: 0 20px;
}
.card-pic {
  height: 255.2px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.card-name {
  font-size: 18px;
  font-weight: bold;
  color: #fdad00;
  margin-top: 15px;
}
.card-tip {
  margin-top: 5px;
  color: #666;
}
.card-overview {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
}
.card-circle {
  background-color: #f2f2f2;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 12px;
  p:first-child {
    color: #fdad00;
    font-size: 22px;
    margin: 20px 0 5px;
  }
}
.flex-around {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  .chart-circle {
    text-align: center;
    height: 120px;
    width: 120px;
    border-radius: 60px;
    border: 8px solid red;
    p:first-child {
      font-size: 24px;
      margin: 15px 0 5px;
    }
    &.orange {
      border-color: #ee8300;
    }
    &.blue {
      border-color: #0ca0d2;
    }
    &.pink {
      border-color: #f48665;
    }
  }
  .chart-text {
    font-size: 16px;
    &.orange {
      color: #ff8c00;
    }
    &.blue {
      color: blue;
    }
    &.green {
      color: green;
    }
    &.red {
      color: red;
    }
  }
}
.img-wrapper {
  padding-bottom: 75%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
  }
}
</style>
